<template>
  <v-text-field
    ref="inputRef"
    :height="height"
    v-model="inputValue"
    v-mask="mask"
    class="rcc-text-input"
    :label="label"
    :disabled="isDisabled"
    :hide-details="hideDetails"
    :type="type"
    :min="min"
    :max="max"
    :hide-spin-buttons=isHideSpinButtons
    :rules="rules"
    dense
    outlined
    v-bind="$attrs"
    v-on="$listeners"
    @keyup.enter="$emit('keyup-enter')"
    @keyup="handleInputKeyup"
    @keydown="$emit('keydown', $event)"
    @paste="$emit('paste', $event)"
  >
    <template v-slot:append>
      <slot name="append" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'RccTextInput',

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number],
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    mask: {
      type: String,
      default: ''
    },

    width: {
      type: String,
      default: null
    },

    height: {
      type: String,
      default: null
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    hideDetails: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'text'
    },

    min: {
      type: Number,
      default: null
    },

    max: {
      type: Number,
      default: null
    },

    rules: {
      type: Array,
      default: () => []
    },

    isHideSpinButtons: {
      type: Boolean,
      default: true
    },

    isLowerCase: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    inputValue: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      }
    }
  },

  mounted() {
    if (this.width) {
      this.$refs.inputRef.$el.style.width = this.width
    }
  },

  methods: {
    handleInputKeyup() {
      if (this.isLowerCase) {
        this.inputValue = this.inputValue?.toLowerCase()
      }
    }
  }
}
</script>

<style lang="scss">
.rcc-text-input {
  .v-input {
    &__slot {
      background: $text-input-bg !important;

      fieldset {
        border-color: $text-input-border-color;
      }

      &:hover {
        fieldset {
          border-color: $text-input-hovered-border-color;
        }
      }

      .v-label, input {
        font-size: $base-font-size;
      }
    }
  }

  &.v-input--is-disabled {
    fieldset {
      border-color: $text-input-disabled-border-color;
    }

    &:hover {
      fieldset {
        border-color: $text-input-disabled-border-color;
      }
    }
  }
}
</style>
